<template>
  <b-row>
    <b-col sm="12">
      <b-card header-tag="header" footer-tag="footer">
        <b-row>
          <b-col sm="7">
            <h4 id="traffic" class="card-title mb-0">Actualizaciones Externas</h4>
            <div class="small text-muted">Muestra todas las actualizaciones de precio desde servicios externos</div>
          </b-col>        
        </b-row>
      </b-card>
      
      <b-card header-tag="header" footer-tag="footer">     
        <b-row>          
          <b-col lg="6">
            <b-row>
              <b-col md="12">
                <b-button @click="filterData()" size="sm" variant="outline-success pull-right mb-1 w-100">
                  <b-icon icon="arrow-repeat"></b-icon> Refrescar
                </b-button>
              </b-col>
              <b-col md="12">         
                <b-table class="mb-0"
                        ref="table"
                        responsive="sm"
                        head-variant="dark"
                        :hover="true"
                        :small="true"
                        :fixed="false"
                        :items="table.items"
                        :fields="table.fields"                    
                        :current-page="table.currentPage"
                        :per-page="table.perPage"
                        :busy="table.isBusy"                          
                        v-if="table.items.length || table.isBusy">

                    <template v-slot:table-busy>
                      <div class="text-center text-danger my-2">
                        <b-spinner class="align-middle"></b-spinner>
                        <strong>Cargando...</strong>
                      </div>
                    </template>

                    <template v-slot:cell(date)="data">
                      {{ moment(data.item.created_at).format('DD MMMM YYYY - HH:mm') }}
                    </template>

                    <template v-slot:cell(origen)="data">
                      {{ data.item.origen }}
                    </template>

                    <template v-slot:cell(count)="data">
                      <div v-if="parseFloat(data.item.count_rows)>0">
                        <div v-if="parseFloat(data.item.count_rows)==1">
                          <i><b>{{ data.item.count_rows }}</b> Registro</i>
                        </div>
                        <div v-else>  
                          <i><b>{{ data.item.count_rows }}</b> Registros</i>
                        </div>
                      </div>
                      <div v-else>
                        <i>Sin Registros</i>                    
                      </div>
                    </template>                

                    <template v-slot:cell(f_action)="data">
                      <b-dropdown right 
                                  text="Acción" 
                                  size="sm" 
                                  variant="outline-dark" 
                                  class="pull-right"
                                  v-if="data.item.count_rows > 0">
                        <b-dropdown-item @click="undo(data.item)" variant="danger">
                          <b-icon icon="eraser-fill"></b-icon> Anular
                        </b-dropdown-item>                   
                        <b-dropdown-header>Acciones</b-dropdown-header>                    
                        <b-dropdown-item @click="download(data.item)">
                          <b-icon icon="download"></b-icon> Descargar
                        </b-dropdown-item>                                     
                      </b-dropdown>
                    </template>
                </b-table>
                <b-alert v-else variant="warning" show>No se encontraron registros</b-alert>
              </b-col>
            </b-row>
          </b-col>
          <b-col lg="6">
            <b-card bg-variant="light" header="Ayuda" text-variant="dark">
              <b-card-text>
                Mostramos todas las <b>actualizaciones de precios</b> realizadas desde integraciones externas via <b>API</b>
                <br><br>
                Desde la opción <i><b>Anular</b></i>, el sistema vuelve el precio modificado al estado anterior.
              </b-card-text>
            </b-card>            
          </b-col>
        </b-row>  
      </b-card>

      <b-card header-tag="header" footer-tag="footer">
        <b-row>
          <b-col>
            <b-button type="button" variant="outline-dark" size="sm" @click="$router.go(-1)">
              <i class="fa fa-angle-double-left"></i>
              Volver
            </b-button>                        
          </b-col>

          <b-col>
            <nav v-if="table.tablePaginate">
              <b-pagination class="pull-right mb-0"
                            size="sm"
                            pills=""
                            :total-rows="table.tablePaginate.total"
                            :per-page="table.tablePaginate.per_page"
                            v-model="table.currentPage" 
                            @input="filterImport()" />
            </nav>
          </b-col>          
        </b-row>
      </b-card>      
    </b-col>
  </b-row>
</template>
<script>
  import serviceAPI from './services'
  import Error from '@/handler/error'
  import Session from '@/handler/session'  
  import Profiles from '@/config/profiles'
  import Modules from '@/config/modules'
  import Helper from '@/handler/helper' 
  import moment from 'moment'
  import Funciones from '@/handler/funciones' 

  export default {   
    data: () => {
      return {      
        access: {
          module_id: Modules.COSTOS,
          profile_id: Profiles.PERSONAL,
          view_reference: 'crudUpdateExternal',
        }, 
        table : {
          items: [],
          fields: [       
            {key: 'date', label: 'Fecha', class: "align-middle"},
            {key: 'origen', label: 'Origen', class: "align-middle"},
            {key: 'count', label: 'Registros', class: "align-middle"},            
            {key: 'f_action', label:'', class: "align-middle"},
          ],          
          mostrarFiltros: true,
          currentPage: 1,
          perPage: 0,
          isBusy: false,
          tablePaginate: null,                 
        },        
      }
    },
    created () {     
      /* Configurar permisos de vistas y elementos */
      Helper.hasAccessView(this.access)
      /* Fin configuracion */
    }, 
    mounted() {        
      this.filterData()   
    },
    methods: {                
      filterData() {      
        this.table.isBusy = true
           
        var result = serviceAPI.obtenerUpdateExternalListCost(this.table.currentPage)        
        result.then((response) => {        
          var data = response.data
          this.table.tablePaginate = data

          this.table.items = data.data   
     
          this.table.isBusy = false           
        })
        .catch(error => {            
          this.table.isBusy = false                    
          this.$awn.alert(Error.showError(error))
        });   
      },      
      download(item) {    
        this.$bvModal.msgBoxConfirm('¿Descargar registros?', {
          title: 'Descargar',
          size: 'md',
          buttonSize: 'md',
          okVariant: 'dark',
          okTitle: 'DESCARGAR',
          cancelTitle: 'Cancelar',
          footerClass: 'p-2',
          headerBgVariant: 'dark',
          headerTextVariant: 'white',
          hideHeaderClose: false,
          centered: false
        })
        .then(value => {          
          if (value) {
            let loader = this.$loading.show();
            this.$awn.info("Estamos procesando el archivo, por favor espere...");                      
            let data = JSON.parse(item.data_products)
            
            let csvContent = 'data:text/csv;charset=utf-8,' +
              Object.keys(data[0]).join(',') + '\n' +
              data.map(item => Object.values(item).join(',')).join('\n');

            let encodedUri = encodeURI(csvContent);
            let link = document.createElement('a');
            link.setAttribute('href', encodedUri);
            link.setAttribute('download', 'datos.csv');
            
            document.body.appendChild(link);            
            link.click();
            document.body.removeChild(link);        
            loader.hide()
          }
        })        
        .catch(error => {                              
          this.$awn.alert(Error.showError(error))
        });   
      },
      undo(item) {
        this.$bvModal.msgBoxConfirm('Al anular esta modificación de precios, cada registro modificado volverá a su precio anterior. ¿Continuar con la anulación?', {
          title: 'Anulación',
          size: 'md',
          buttonSize: 'md',
          okVariant: 'danger',
          okTitle: 'ANULAR',
          cancelTitle: 'Cancelar',
          footerClass: 'p-2',
          headerBgVariant: 'danger',
          headerTextVariant: 'white',
          hideHeaderClose: false,
          centered: false
        })
        .then(value => {          
          if (value) {
            let loader = this.$loading.show();

            var result = serviceAPI.anularUpdateExternalListCost({
              id: item.id,
            })           
            result.then((response) => {              
              loader.hide()
              
              this.filterData()              
              
              this.$awn.success("Finalizamos la anulación de los precios modificados");
            })
            .catch(error => {                 
              loader.hide()
              this.$awn.alert(Error.showError(error));
            })            
          }
        })        
        .catch(error => {                              
          this.$awn.alert(Error.showError(error))
        });          
      }
    }    
  }
</script>
